import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const Holidays2022Section = () => (
  <div className='bg-red-900 py-12'>
    <div className='w-11/12 max-w-screen-lg mx-auto'>
      <StaticImage
        src="../../images/sections/happy-holidays-2022.png"
        width={2500}
        quality={95}
        formats={["AUTO", "WEBP"]}
        alt=""
        className='md:w-full mx-auto'
      />
      <h1 className='text-white text-4xl md:text-6xl font-semibold mt-6'>Merry Christmas from all of us at Team Command!</h1>

      <p className='text-white font-semibold mt-6'>We hope this holiday season brings joy and happiness to you and your loved ones. Thank you for your support and loyalty throughout the year. We can't wait to see what the new year brings. Wishing you a very merry Christmas and a happy holiday season!</p>
    </div>
  </div>
)

export default Holidays2022Section