import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import Holidays2022Section from "../components/sections/Holidays2022Section"

const AnnouncementPage = () => (
  <MainContainer>
    <HeadData
      title='Happy Holidays'
      description='Happy Holidays from all of us at Team Command!'
    />

    <Holidays2022Section />
  </MainContainer>
)

export default AnnouncementPage